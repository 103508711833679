import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './AboutPeopleCard.scss';


export default function AboutPeopleCard(props) {
  return (
    <div className="AboutPeopleCard">
      <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
        <div className="img-area">
          <div className="img-container">
            <img src={props.imagePath} alt={props.name} />
          </div>
          {
            props.linkedInLink ? (
              <a href={props.linkedInLink} target="_blank" rel="noopener noreferrer">
                <img src="/assets/icons/linkedIn-icon.png" alt="LinkedIn" className="linkedin-icon" />
              </a>
            ) : null
          }
        </div>
      </ScrollAnimation>
      <div className="data">
        <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
          <div className="bar" />
          <div className="name">{props.name}</div>
          {
            props.memberSince ? (
              <p className="member-since">
                {props.memberSince}
              </p>
            ) : null
          }
          <div className="designations">{props.designations.map(designation => (<p key={designation}>{designation}</p>))}</div>
          {
            props.email ? (
              <a href={"mailto:" + props.email} target="_blank" rel="noopener noreferrer" className="email">
                {props.email}
              </a>
            ) : null
          }
          {
            props.testimonial ? (
              <div className="testimonial">
                {props.testimonial}
              </div>
            ) : null
          }
        </ScrollAnimation>
      </div>
    </div>
  );
}