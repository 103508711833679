import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import SectionHeader from "../../../shared/section-header/SectionHeader";
import AdvisorPeopleCard from "../../../shared/advisor-people-card/AdvisorPeopleCard";

import "./Advisors.scss";

import AdvisorsData from "./advisors-data.json";

export default function Advisors() {
  return (
    <div className="Advisors">
      {/* <div className="catch-link" id="advisory_boards" /> */}

      <div className="people-pages">
        {AdvisorsData.map((country) => (
          <div className="page-section-auto container" key={country.id}>
            <div className="catch-link" id={country.id} />
            <SectionHeader>
              ADVISORY BOARD <br />
              {country.name}
            </SectionHeader>

            <div className="body">
              <div className="people">
                {country.people.map((person, index) => (
                  <AdvisorPeopleCard
                    key={person.id}
                    index={index}
                    name={person.name}
                    image={"assets/images/advisors/" + person.image + ".png"}
                    descriptions={person.descriptions}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="prince-philipp page-section-auto container">
        <SectionHeader>
          The ert alumni <br />
          organisation
        </SectionHeader>

        <div className="body">
          <div className="left">
            <AdvisorPeopleCard
              name="H.S.H. Prince Philipp von und zu Liechtenstein"
              index={1}
              image="assets/images/about/senior-entrepreneur/H.S.H. Prince Philipp von und zu Liechtenstein.png"
              descriptions={[
                "Chairman // LGT Group Foundation",
                "Honorary Chairman of the ERT Alumni organization",
              ]}
            />
          </div>
          <div className="right">
            <ScrollAnimation animateIn="fadeInRight" animateOnce delay={300}>
              <div className="text">
                After turning 66, <br />a member automatically <br /> becomes a{" "}
                <strong>
                  «Senior <br /> Entrepreneur»
                </strong>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}
