import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './AdvisorPeopleCard.scss';

export default function AdvisorPeopleCard(props) {
  return (
    <div className="AdvisorPeopleCard">
      <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
        <div className="img-container">
          <img src={props.image} alt={props.name} />
        </div>
        <div className="data">
          <div className="name">
            {props.name}
          </div>
          <div className="descriptions">
            {props.descriptions.map(description => (<p key={description}>{description}</p>))}
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}