import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './About.scss';

export default function About() {
  return (
    <div className="About page-section-auto blackish-background">
      <div className="catch-link" id="about" />

      <div className="container">
        <div className="h-gap"></div>
        <SectionHeader>
          ABOUT THE ENTREPRENEURS' <br />ROUNDTABLE
        </SectionHeader>

        <div className="body">
          <div className="left">
            <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
              <p>
                <span className="strong">In 1999</span>, <a href="#thomas_ladner">Thomas Ladner</a> initiated the first national chapter of the Entrepreneurs‘ Roundtable (ERT) in Switzerland. <a href="#pascal_forster">Pascal Forster</a> joined as co-founder in 2001.
              </p>
              <p>
                The <span className="strong">purpose of the ERT</span> is for the members to freely share their thoughts and ideas on political, economic, societal and personal matters. What started as a loosely-linked community of leaders evolved over the years into a <span className="strong">unique circle of friends</span>.
              </p>
              <p>
                The ERT consists of <span className="strong">five country chapters</span>: Switzerland, Germany (2008), France (2017), the Netherlands (2017) and Poland (2020). The country chapters meet individually several times a year, with each event being hosted by a different chapter member and coordinated by the ERT Founders. The respective <span className="strong">Advisory Boards</span> provide input on strategic decisions and on the admission of new members.
              </p>
              <p>
                At our <span className="strong">international events</span>, members from all chapters congregate to interact with each other, as well as with political and business leaders from the host country.
              </p>
              <p>
                The <span className="strong">Chatham House Rule</span> applies to all ERT events.
              </p>
            </ScrollAnimation>
          </div>

          <div className="space"></div>
          <div className="right">
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <span className="quote">“</span>
              <p>
                The unpretentious community of the ERT provides a secure platform for members to establish trusted friendships across industries and country borders, based on common values and shared experiences uniquely tailored to them.
              </p>
            </ScrollAnimation>
          </div>
        </div>

      </div>

    </div>
  );
}