import React from 'react';

export default function Link(props) {
  const { link, activeMainLink, activeLink, setActiveLink, activeDropdown, setActiveDropdown, setIsPhoneMenuOpen } = props;

  const renderLink = () => {
    return (
      <a
        key={link.id}
        href={'#' + link.id}
        id={"link-" + link.id}
        className={"link " + (activeLink === link.id ? ' active' : '')}
        onClick={(e) => {
          setActiveLink(link.id);
          setIsPhoneMenuOpen(false);
        }}
      >
        {link.title}
      </a>
    );
  };

  const renderDropdownTrigger = () => {
    return (
      <span
        key={link.id}
        id={"link-" + link.id}
        className={"link " + (activeMainLink ? ' active' : '')}
        onClick={(e) => {
          if (activeDropdown === link.id) {
            setActiveDropdown(null);
          } else {
            setActiveDropdown(link.id);
          }
        }}
      >
        {link.title}
        <div className={'dropdown ' + (activeDropdown === link.id ? 'open' : '')}>
          {renderDropdown(link.links)}
        </div>
      </span>
    );
  };

  const renderDropdown = (links) => {
    return links.map(link => (
      <a
        key={link.id}
        href={'#' + link.id}
        id={"link-" + link.id}
        className={"dropdown-link " + (activeLink === link.id ? ' active' : '')}
        onClick={(e) => {
          setActiveLink(link.id);
          setIsPhoneMenuOpen(false);
        }}
      >
        {link.title}
        {link.links ?
          (
            <div className={'dropdown ' + (activeDropdown === link.id ? 'open' : '')}>
              {renderDropdown(link.links)}
            </div>
          ) : null
        }
      </a>
    ));
  };

  return (
    <div className="Link">
      {
        link.links ? renderDropdownTrigger() : renderLink()
      }
    </div>
  );
}
