import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './Home.scss';

import LOGO_BG from '../../../../assets/images/logo-background.svg';

export default function Home() {
  return (
    <div className="Home">
      <div className="catch-link" id="home" />
      <div className="home-container">

        <ScrollAnimation animateIn="zoomIn" animateOnce>
          <img src={LOGO_BG} alt="ert-logo-bg" className="background-logo" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="zoomIn" className="home-text-img-container" delay={300} animateOnce>
          <img src={'/assets/images/home/home-text.png'} alt="home text" className="home-text-img" />
        </ScrollAnimation>

      </div>
    </div>
  );
}