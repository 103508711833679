import React from 'react';

import './Testimonials.scss';

import SectionHeader from '../../../shared/section-header/SectionHeader';
import AboutPeopleCard from '../../../shared/about-people-card/AboutPeopleCard';

import TestimonialData from './testimonial-data.json';

export default function Testimonials() {
  return (
    <div className="Testimonials page-section-auto container">
      <div className="catch-link" id="testimonials" />

      <SectionHeader>
        TESTIMONIALS
      </SectionHeader>

      <div className="body">
        {
          TestimonialData.map(person => (
            <div key={person.id} className="card">
              <AboutPeopleCard
                name={person.name}
                imagePath={'/assets/images/testimonials/' + person.image + '.png'}
                memberSince={person.memberSince}
                designations={person.designations}
                testimonial={(
                  <p>
                    {person.testimonial}
                  </p>
                )}
              />
            </div>
          ))
        }
      </div>

    </div>
  );
}