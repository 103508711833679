import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import SectionHeader from "../../../shared/section-header/SectionHeader";
import AboutPeopleCard from "../../../shared/about-people-card/AboutPeopleCard";

import "./Founders.scss";

export default function Founders() {
  return (
    <div className="Founders">
      <div className="catch-link" id="founders" />

      <div className="founder-section page-section-auto container">
        <SectionHeader>THE FOUNDERS</SectionHeader>

        <div className="common-text">
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
            Pascal Forster and Thomas Ladner met during their studies at the
            University of St. Gallen HSG in Switzerland. Throughout their
            professional lives they have been passionate about creating
            communities of business and thought leaders. In 1999, Thomas founded
            the Entrepreneurs' Roundtable which Pascal joined as co-founder in
            2001. They continued to co-found several other organizations such as
            the prestigious Club zum Rennweg (
            <a
              href="https://www.clubzumrennweg.ch"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.clubzumrennweg.ch
            </a>
            ), the Zurich-based Grasshopper Griffith-Club and WORLD.MINDS
            Foundation (
            <a
              href="https://www.worldminds.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.worldminds.com
            </a>
            ).
          </ScrollAnimation>
        </div>

        <div className="body">
          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="thomas_ladner" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Dr. Thomas Ladner.png"
                    alt="Dr. Thomas Ladner"
                  />
                </div>

                <div className="about">
                  <div className="name">Dr. Thomas Ladner</div>

                  <div className="designations">
                    <p>Attorney at law</p>
                  </div>

                  <div className="email-container">
                    <a
                      href="mailto:thomas.ladner@e-r-t.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="email"
                    >
                      thomas.ladner@e-r-t.org
                    </a>
                  </div>

                  <a
                    href="https://linkedin.com/in/dr-thomas-ladner-704522"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-icon-link"
                  >
                    <img
                      src="/assets/icons/linkedIn-icon.png"
                      alt="LinkedIn"
                      className="linkedin-icon"
                    />
                  </a>
                </div>
              </div>
            </ScrollAnimation>
            <div className="data">
              <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                <div className="testimonial">
                  <p>
                    Thomas Ladner started his legal career with the law firm
                    Homburger in Zurich. From 2000 - 2004, he lectured on
                    corporate law and contract law at the University of St.
                    Gallen HSG from where he also holds a Ph.D. In 2002, he
                    joined one of Switzerland’s leading law firms
                    Meyerlustenberger Lachenal as their youngest Partner.
                    In 2009, Thomas moved on to become Partner at a San
                    Francisco-based Private Equity firm in charge of their
                    European investments (until 2014) while simultaneously
                    serving as Counsel with MLL Legal AG, a position he still holds.
                  </p>
                  <p>
                    In a personal capacity, Thomas founded and co-founded a
                    number of tech and financial services businesses, two of
                    which went public. He was the Founding Chairman of the Club
                    zum Rennweg and of WORLD.MINDS Foundation and the Founding
                    Vice-Chairman of the Grasshopper Griffith Club. He also
                    served on the board of the Swiss Management Association
                    (Schweizerische Management Gesellschaft SMG) for seven
                    years.
                  </p>
                  <p>
                    Today Thomas acts as professional board member in more than
                    a dozen companies ranging from biotech and blockchain
                    start-ups to mature companies in the construction,
                    investment, trading and service industries. In
                    addition he is a member of the board of the Swiss Defence Society. 
                    The leading Swiss business publication Bilanz describes him as one of
                    Switzerland’s{" "}
                    <span className="italic">
                      “most influential business lawyers“
                    </span>
                    .
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="AboutPeopleCard">
            <div className="catch-link-founder" id="pascal_forster" />
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
              <div className="img-area">
                <div className="img-container">
                  <img
                    src="/assets/images/founders/Pascal Forster.png"
                    alt="Pascal Forster"
                  />
                </div>

                <div className="about">
                  <div className="name">Pascal Forster</div>
                  <div className="designations">
                    <p>Executive Search Professional</p>
                  </div>
                  <div className="email-container">
                    <a
                      href="mailto:pascal.forster@e-r-t.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="email"
                    >
                      pascal.forster@e-r-t.org
                    </a>
                  </div>

                  <a
                    href="https://ch.linkedin.com/in/pascal-forster-23116424"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-icon-link"
                  >
                    <img
                      src="/assets/icons/linkedIn-icon.png"
                      alt="LinkedIn"
                      className="linkedin-icon"
                    />
                  </a>
                </div>
              </div>
            </ScrollAnimation>
            <div className="data">
              <div className="testimonial">
                <ScrollAnimation animateIn="zoomIn" animateOnce delay={300}>
                  <p>
                    Pascal Forster’s entire professional life has centered
                    around his passion for talent and technology. He started
                    coding while in high school and went on to receive a B.A. in
                    social sciences followed by an M.B.A. from the University of
                    St. Gallen HSG. In 2005, he completed the Executive Master
                    Program “Coaching and Consulting for Coach” at INSEAD in
                    France.
                  </p>
                  <p>
                    Pascal spent his initial professional years at HP/Compaq,
                    both in the Silicon Valley and in Zurich. In 1996 he started
                    his career in Executive Search, joining a global search
                    firm, becoming their youngest principal in Europe in 1999.
                    Over the next 20 years he recruited more than 400 CEOs,
                    Board Members and Senior Managers for leading clients, from
                    Fortune 500 companies to fast-growing technology companies.
                    He actively supports entrepreneurs from different industries as 
                    a coach, mentor and as an investor.
                  </p>
                  <p>
                    Pascal co-founded the WORLD.MINDS Foundation, the Club zum Rennweg 
                    and the Grasshopper Griffith Club. In 1999, together with his wife 
                    Margarita, Pascal founded “Fundacion Avanzar” which actively supports women
                    in Ecuador to become entrepreneurs through a number of education,
                    mentoring and financing programs.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="co-founder-section page-section-auto container">
        <div className="body">
          <div className="side left">
            <SectionHeader>
              CO-FOUNDER <br /> FRANCE CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/Carl Azar.png"
              name="Carl Azar"
              designations={[]}
            />
          </div>
        </div>
        <div className="body">
          <div className="side centre">
            <SectionHeader>
              CO-FOUNDER <br />
              NETHERLANDS CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/testimonials/Ton Buchner.png"
              name="Ton Büchner"
              designations={[]}
            />
          </div>
        </div>
        <div className="body">
          <div className="side centre">
            <SectionHeader>
              CO-FOUNDER <br />
              POLAND CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/Wojciech Szczepaniak.png"
              name="Wojciech Szczepaniak"
              designations={[]}
            />
          </div>
          <div className="side right">
            <SectionHeader>
              CO-FOUNDER <br />
              POLAND CHAPTER
            </SectionHeader>

            <AboutPeopleCard
              imagePath="assets/images/founders/markus_sieger.png"
              name="Markus Sieger"
              designations={[]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
