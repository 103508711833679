import React from "react";

import "./PrivacyPolicy.scss";

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy container">
      <h1>Privacy Policy</h1>
      <p>
        Responsible person within the meaning of the data protection laws, in
        particular the EU General Data Protection Regulation (GDPR):
        <br />
        Entrepreneurs' Roundtable AG <br />
        Mrs. Smita Kishore <br />
        Telefon: +41 41 768 1101 <br />
        E-Mail:{" "}
        <a href="mailto:smita.kishore@e-r-t.org">
          smita.kishore@e-r-t.org
          <br />
        </a>
        Website: <a href="http://www.e-r-t.org">www.e-r-t.org</a>
      </p>

      <h2>General</h2>
      <p>
        We take the protection of your personal data privacy very seriously. We
        handle your personal data in complete confidence and strictly in
        accordance with statutory data protection regulations as well as this
        privacy policy.
      </p>
      <p>
        By using this website, you agree to the collection, processing and use
        of data as described below. This website is accessible without
        registration. Data such as viewed pages or names of files accessed, date
        and time are stored on our server purely for statistical purposes,
        without it being related directly to you. We do not collect personal
        data and no data is passed on to third parties.
      </p>

      <h2>Cookies</h2>
      <p>This website does not use any cookies.</p>

      <h2>Server Log Files</h2>
      <p>
        The website collects and stores information in so-called server log
        files which your browser automatically transmits, such as:
      </p>
      <ul>
        <li>Browser type and version</li>
        <li>The operating system being used</li>
        <li>Referrer URL</li>
        <li>Host name of the accessing computer</li>
        <li>Time of the server request</li>
      </ul>
      <p>
        This data is not attributable to specific persons nor merged with other
        data sources. We reserve the right to check this data retrospectively,
        should there be specific indications of illegal use.
      </p>

      <h2>LinkedIn</h2>
      <p>
        This website uses functions of the LinkedIn network, whose provider is
        LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA.
        Each time one of our pages featuring LinkedIn functions is accessed, a
        connection is established to LinkedIn's servers informing them you have
        accessed our website with your IP address. By clicking a LinkedIn icon
        on our website while logged in to your LinkedIn page, LinkedIn is able
        to assign your visit to our website. We explicitly state that as the
        website provider, we have no knowledge of the nature of the data
        transmitted or how it is used by LinkedIn. For further information on
        this, please consult the data privacy policy of LinkedIn here:{" "}
        <a href="https://www.linkedin.com/legal/privacy-policy">
          https://www.linkedin.com/legal/privacy-policy
        </a>
      </p>
      <br />

      <h2>Changes to Privacy Policy</h2>
      <p>
        We reserve the right to make changes to this privacy policy at any point
        without prior notice. The current version as published on the website
        applies.
      </p>

      <h2>Questions?</h2>
      <p>
        For any questions on this privacy policy contact{" "}
        <a href="mailto:smita.kishore@e-r-t.org">smita.kishore@e-r-t.org</a>
        .&nbsp;
      </p>
      <p>Baar, May 2020&nbsp;</p>
    </div>
  );
}
