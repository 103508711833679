import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './Membership.scss';

export default function Membership() {
  return (
    <div className="Membership page-section-auto container">
      <div className="catch-link" id="membership" />
      <SectionHeader>
        MEMBERSHIP
      </SectionHeader>

      <div className="body">

        <div className="left">
          <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={300}>
            <img src="/assets/images/membership/member-text-1.png" alt="member-text-1" className="member-text-img-1" />
          </ScrollAnimation>
        </div>

        {/* <div className="space" /> */}

        <div className="right">
          <ScrollAnimation animateIn="fadeInDown" animateOnce delay={300}>
            <img src="/assets/images/membership/member-text-2.png" alt="member-text-2" className="member-text-img-2" />
          </ScrollAnimation>
        </div>

      </div>
    </div>
  );
}