import React from 'react';
import './MainPage.scss';

// Component Components
import Navbar from '../../shared/navbar/Navbar';

// Pages
import Home from './home/Home.jsx';
import About from './about/About.jsx';
import Membership from './membership/Membership.jsx';
import Advisors from './advisors/Advisors.jsx';
import Founders from './founders/Founders.jsx';
import Testimonials from './testimonials/Testimonials.jsx';
import Contact from './contact/Contact.jsx';

export default function MainPage() {
  return (
    <div className="MainPage">
      <Navbar />
      <div className="page-padding" />
      <div className="router-pages">
        <Home />
        <About />
        <Membership />
        <Advisors />
        <Founders />
        <Testimonials />
        <Contact />
      </div>
    </div>
  );
}
